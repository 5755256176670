module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Local 417 Eats","short_name":"Local 417 Eats","description":"The one stop shop to find all the local restaurants in the Springfield, MO area. No chains. All local.","lang":"en","display":"standalone","start_url":"/","background_color":"#2d3748","theme_color":"#ffe169","icon":"src/images/local417eats-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
